/* Radikal Fonts */

@font-face {
  font-family: 'Radikal';
  src: url('../fonts/Radikal.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Radikal';
  src: url('../fonts/RadikalUltraThin.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Radikal';
  src: url('../fonts/RadikalLight.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Radikal';
  src: url('../fonts/RadikalBold.ttf');
  font-weight: 600;
}

/* Root */

:root {
  --blue: #368bff;
  --blue-secondary: #55c7ff;
  --blue-tertiary: #68e3ff;
  --dark-blue: #002448;
  --red: #FF0033;
  --green: #36C699;
  --grey: #94A2B0;
  --light-grey: #E8ECF7;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

/* Body */

body {
  /* color: var(--dark-blue); */
  color: #000;
  background-color: #fff;
}

body, input, button, textarea {
  font: 400 16px 'Radikal', sans-serif;
}

/* Responsive */

@media only screen and (max-width: 1024px) {
  html {
    font-size: 93.75%;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 87.5%;
  }
}