.menu {
  background: #fff;
  width: 90%;
  height: 7rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  font-weight: 300;
}

.menu > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu img {
  height: 4rem;
}

.menu ul {
  display: flex;
  list-style: none;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.menu ul li {
  margin: 0 1rem;
}

.menu ul li a {
  text-decoration: none;
  color: var(--dark-blue);
  font-size: 1.1rem;
}

.login a {
  border: 2px solid var(--blue-secondary);
  border-radius: 2rem;
  padding: 0.5rem 3rem;
  transition: 0.2s linear;
}

.login a:hover {
  background: var(--blue-secondary);
  color: #fff;
}

.menu ul li a:not(.login a):hover {
  text-decoration: underline;
}

#menuBtn {
  width: 3rem;
  height: 3rem;
  display: none;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  z-index: 999;
  position: relative;
}

@media only screen and (max-width: 768px) {
  #menuBtn {
    display: flex;
  }

  .menu .pages ul {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    background: #E8ECF7;
  }

  .menu .pages ul.active {
    display: flex;
  }

  .menu .pages ul li {
    margin: 2rem 0;
  }

  .menu .pages ul li a {
    font-size: 1.6rem;
  }
}