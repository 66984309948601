.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--blue-secondary);
}

.header {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-bottom-right-radius: 400px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header-container {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
}

.header-container .text {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.header-container .text h1 {
  font-size: 2.5rem;
  font-weight: 400;
}

.header-container .text p {
  font-size: 1.2rem;
  text-align: justify;
  margin: 2rem 0;
  width: 65%;
  font-weight: 300;
}

.header-container .text a {
  font-size: 1.2rem;
  text-decoration: none;
  border: 2px solid transparent;
  border-radius: 2rem;
  padding: 0.5rem 3rem;
  transition: 0.2s linear;
  background: var(--blue-secondary);
  color: #fff;
}

.header-container .text a:hover {
  background: transparent;
  border: 2px solid var(--blue-secondary);
  color: var(--dark-blue);
}

.header-container .images {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.header-container .images img:first-child {
  height: 20vw;
}

.header-container .images img:last-child {
  height: 30vw;
}

section.white, section.blue, section.rounded-card  {
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

section.white {
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* color: var(--dark-blue); */
  color: #000;
}

section.blue {
  color: #fff;
}

section h2 {
  font-size: 2rem;
  font-weight: 400;
}

section h3 {
  font-size: 1.6rem;
  font-weight: 300;
}

.cards {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.card {
  margin: 0 auto;
  height: 40rem;
  width: 25rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--dark-blue);
  position: relative;
  margin: 2rem auto;
}

.card img {
  height: 30rem;
  width: 100%;
  padding: 2rem;
}

.card-container {
  background: #e8ecf798;
  width: 100%;
  height: 15rem;
  border-radius: 0 0 2rem 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card-container p.title {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.card-container .description {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 300;
  max-width: 80%;
  line-height: 1.5rem;
}

section.rounded-card {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-top-left-radius: 400px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media only screen and (max-width: 768px) {

  .header {
    border-radius: 0;
    height: initial;
  }

  section.rounded-card {
    border-radius: 0;
    height: initial;
  }

  .header-container {
    flex-direction: column-reverse;
    margin-top: initial;
  }

  .header-container .text, .header-container .images {
    width: 100%;
    margin: 2rem 0;
  }

  .header-container .images {
    justify-content: center;
  }

  .header-container .images img:first-child {
    height: 35vw;
  }

  .header-container .images img:last-child {
    height: 50vw;
  }

  .header-container .text {
    align-items: center;
    text-align: center;
  }

  .header-container .text p {
    text-align: center;
  }
}