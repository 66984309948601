.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-secondary);
}

.container > * {
  width: 50%;
}

.login-text {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 3.75rem;
  width: 100%;
}

.logo {
  position: absolute;
  top: 3.75rem;
  left: 3.75rem;
}

.login-text h1 {
  font-size: 5.6rem;
  color: #fff;
  max-width: 525px;
  font-weight: 600;
}

.logo img {
  height: 4rem;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
}

.login-form {
  background: #fff;
  height: 100vh;
  padding: 3.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.login-form > button {
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--dark-blue);
  transition: .3s ease;
}

.login-form > button > :first-child {
  margin-right: 0.5rem;
}

.login-form > button:hover {
  opacity: 0.8;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--dark-blue);
}

.form h1 {
  font-size: 3rem;
  margin: 1rem 0;
}

.form form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 75%;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 300;
  color: var(--dark-blue);
  text-align: center;
}

.inputGroup input {
  width: 100%;
  max-width: 420px;
  padding: 1rem 2rem;
  border-radius: 20rem;
  border: 1px solid var(--blue);
  outline: none;
  font-weight: 300;
  margin-bottom: 1rem;
}

.inputGroup input::placeholder {
  color: var(--grey);
}

.inputGroup > * {
  margin: 0.5rem auto;
}

.inputGroup > a {
  color: var(--grey);
  text-decoration: none;
}

.inputGroup > a:hover {
  text-decoration: underline;
}

.inputGroup > span a {
  font-weight: 600;
  text-decoration: none;
  color: inherit;
}

.inputGroup > span a:hover {
  text-decoration: underline;
}

.inputGroup.buttons {
  margin: 1rem 0;
}

.button {
  width: 100%;
  max-width: 420px;
  border: none;
  border-radius: 2rem;
  padding: 1rem 2rem;
  transition: 0.2s linear;
  background: var(--blue);
  cursor: pointer;
  color: #fff;
  font-weight: 300;
  outline: none;
}

.button:hover {
  background: var(--blue-secondary);
  color: #fff;
}

.button:disabled {
  background: var(--grey);
  cursor: default;
}

@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    height: initial;
  }

  .login-form {
    padding: 2rem;
  }

  .login-text {
    display: none;
  }

  .inputGroup input, .button {
    width: 100%;
    max-width: 420px;
  }
}