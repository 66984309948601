.signupContainer {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  text-align: center;
}

.signupContainer form {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.signupContainer form > div:first-child {
  width: 70%;
  margin-right: 30%;
}

.signupContainer form > div:last-child {
  width: 30%;
}

.signup {
  padding: 2rem;
  width: 80%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.signup h1 {
  color: var(--dark-blue);
}

.signup section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1rem 0;
}

.signup section > p {
  font-weight: 300;
  color: var(--grey);
  font-size: 1.25rem;
}

.signup section.genderSection {
  margin-bottom: 1rem;
}

.signup section.genderSection .inputs {
  margin: 1rem;
  width: 100%;
  max-width: 674px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signup section.genderSection .inputs .inputGroup {
  width: 9rem;
  height: 9rem;
}

.signup section.genderSection .inputs .inputGroup input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.signup section.genderSection .inputs .inputGroup label {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 2px solid var(--blue);
  border-radius: 10px;
  color: var(--blue);

  transition: background .3s;

  cursor: pointer;
}

.signup section.genderSection .inputs .inputGroup label:not(:checked):hover {
  opacity: 0.8;
}

.signup section.genderSection .inputs .inputGroup input[type="radio"]:checked + label {
  background: var(--blue);
  color: #fff;
}

/* section.personalData {

} */

section.personalData .inputGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

section.personalData .inputGroup label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}


section.personalData .inputGroupHorizontalContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 674px;
}

section.personalData .inputGroupHorizontal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 674px;
}

section.personalData .inputGroupHorizontalContainer > * {
  width: 48%;
}

section.personalData .inputGroup input {
  width: 100%;
  max-width: 674px;
  padding: 1rem 2rem;
  border-radius: 20rem;
  border: 1px solid var(--blue);
  outline: none;
  font-weight: 300;
  margin-bottom: 1rem;
}

section.personalData .inputGroup small, section.personalData .inputGroupHorizontal small {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--red);
  font-size: 1rem;
}

section.personalData .inputGroup small > *, section.personalData .inputGroupHorizontal small > * {
  margin: 0 0.5rem;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;

  background: var(--light-grey);
}

.profile > * {
  margin: 2.5rem 0;
}

.profile .avatarInput {
  width: 13.75rem;
  position: relative;
}

.profile .avatarInput .avatar {
  height: 13.75rem;
  width: 13.75rem;
  background: #F5F8FA;
  border: 2px dashed var(--blue);
  border-radius: 20px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.profile .avatarInput .imageContainer {
  height: 13.75rem;
  width: 13.75rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.profile .avatarInput .imageContainer .avatarCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 0 20px 0 20px;
  padding: 0.5rem;
  border: 2px solid var(--blue-secondary);
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
}

.profile .avatarInput .imageContainer img.avatar {
  border: 2px solid var(--blue-secondary);
  cursor: default;
  object-fit: cover;

}

.profile .avatarInput input {
  visibility: hidden;
  display: none;
}

.profile .avatarInput p {
  color: var(--blue);
}

.profile .avatarInput p.realNameText {
  color: var(--blue);
  font-size: 1.9rem;
}

.profile .avatarInput p.userNameText {
  font-weight: 300;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile .avatarInput p.userNameText > * {
  margin: 0.5rem;
}

.button {
  width: 90%;
  max-width: 420px;
  border: none;
  border-radius: 2rem;
  padding: 1rem 2rem;
  transition: 0.2s linear;
  background: var(--blue);
  cursor: pointer;
  color: #fff;
  font-weight: 300;
  outline: none;
  position: absolute;
  bottom: 0;
}

.button:hover {
  background: var(--blue-secondary);
  color: #fff;
}

.button:disabled {
  background: var(--grey);
  cursor: default;
}

@media only screen and (max-width: 768px) {
  .signupContainer {
    height: initial;
  }
  
  .signupContainer form > div:first-child {
    width: 100%;
    margin-right: initial;
  }

  .signupContainer form > div:last-child {
    width: 100%;
  }

  .signup section.genderSection .inputs {
    max-width: 500px;
  }

  .signupContainer form {
    flex-direction: column;
  }

  .profile {
    position: relative;
    top: initial;
    right: initial;
  }
}