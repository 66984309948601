.storiesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
  overflow: hidden;
  position: relative;
}

.storiesContainer > h2 {
  font-size: 1.4rem;
  font-weight: 400;
}

/* .storiesContainer > h2 > span:last-child {
  font-weight: 300;
} */

.storiesContainer .stories {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
  border-radius: 10px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.storiesContainer .stories .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;

  text-transform: uppercase;
}

.storiesContainer .stories .overlay span {
  display: block;
  padding: 1rem;
  background: var(--green);
  transition: 0.3s ease-in-out;
  border-radius: 0.2rem;
  opacity: 0.9;
}

.storiesContainer .stories .storyContainer {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  width: 100%;
  position: relative;
  filter: blur(0.4rem);
}

.storiesContainer .stories .storyContainer .story {
  width: 5.93rem;
  margin-top: 0.5rem;
}

.storiesContainer .stories .storyContainer .story:not(:first-child) {
  margin-left: 1.5rem;
}

.storiesContainer .stories .storyContainer .story img {
  width: 5.93rem;
  border-radius: 20rem;
  border: 6px solid var(--green);
}

@media only screen and (max-width: 768px) {
  .storiesContainer {
    max-width: 100%;
  }

  .storiesContainer .stories {
    max-width: 100%;
  }
}