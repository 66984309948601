.app {
  background: var(--light-grey);
  width: 100%;
  min-height: 100vh;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
}

.channelContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  /* width: 63%; */
  color: var(--dark-blue);
  margin-left: 6rem;
  position: relative;
}

.chatBox {
  padding: 1rem;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.chatBox img {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1rem;
}

.chatBox .chatText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1.2rem;
  font-weight: 600;
}

/* .container {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  height: 100vh;
  justify-content: space-between;
  position: relative;
} */

.form {
  background: #fff;
  padding: 1rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form > * {
  margin: 0 0.5rem;
}

.submitBtn {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 50%;
  outline: none;
}

.submitBtn:hover {
  background: var(--light-grey);
}

.formField {
  border: 1px solid #dcdcdc;
  border-radius: 5rem;
  color: var(--dark-blue);
  font-size: 1rem;
  padding: .5rem 1rem;
  width: 100%;
  background: var(--light-grey);
}

.formField::placeholder {
  color: var(--dark-blue);
}

.formField:focus {
  border-color: var(--blue-secondary);
  box-shadow: 0 0 7px var(--blue-secondary);
  outline: none;
}

.list {
  margin: 0;
  padding: 1rem;
  width: 100%;
  height: 82vh;
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end; */
  overflow: auto;
}

.listItem {
  list-style: none;
  display: flex;
  align-items: flex-end;
  margin-bottom: 2rem;
}

.listItem > span:first-child {
  width: 2rem;
  height: 2rem;
}

.closeModal {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
}


.avatar {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 50%;
}

.listItem > :first-child {
  margin-right: 0.5rem;
}

.listItemMine {
  text-align: right;
  list-style: none;
  margin-bottom: 1rem;
}

.messageMine {
  background: #0099FF;
  color: #fff;
  text-align: left;
  border: 1px solid transparent;
  border-radius: 25px 25px 0 25px;
  display: inline-block;
  list-style: none;
  padding: .5rem 1rem;

  max-width: 35rem;

  word-break: break-all;
}

.messageOther {
  background: #fff;
  color: #000;
  text-align: left;
  border: 1px solid transparent;
  border-radius: 25px 25px 25px 0;
  display: inline-block;
  list-style: none;
  padding: .5rem 1rem;

  max-width: 35rem;

  word-break: break-all;
}

@media only screen and (max-width: 768px) {
  .app {
    display: block;
  }

  .channelContainer {
    padding: 1rem;
    width: 100%;
    margin-left: 0;
    padding-bottom: 7rem;
    padding-top: 8.5rem;
  }

  .list {
    height: 56vh;
    margin-top: 5rem;
  }

  .chatBox {
    position: absolute;
    top: 7rem;
    left: 0rem;
  }

  .form {
    bottom: 10%;
    padding: 1rem 1rem 4rem 1rem;
  }
}