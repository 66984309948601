.app {
  background: var(--light-grey);
  width: 100%;
  min-height: 100vh;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
}

.appContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  /* width: 63%; */
  padding: 2rem;
  color: var(--dark-blue);
  margin-left: 6rem;
  position: relative;
}

.appContainer .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appContainer > .header > h1 > span:first-child {
  font-weight: 300;
}

.appContainer > .header > h1 > span:last-child {
  font-weight: 400;
}

.appContainer .header .searchContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.appContainer .header .searchContainer .search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1000; 
}

.appContainer .header .searchContainer .search > :first-child {
  position: absolute;
  left: 1.5rem;
}

.appContainer .header .searchContainer .searchFieldContainer {
  width: 18rem;
  padding: 4rem 1.5rem 1rem 1.5rem;
  position: absolute;
  background: #fff;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 2rem 2rem 10px 10px;
  z-index: 999;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;

}

.appContainer .header .searchContainer .searchFieldContainer .searchFields {
  display: flex;
  text-decoration: none;
  color: var(--dark-blue);
}

.appContainer .header .searchContainer .searchFieldContainer > .searchFields:not(:first-child) {
  margin-top: 1rem;
}

.appContainer .header .searchContainer .searchFieldContainer .searchFields .searchFieldText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
}

.appContainer .header .searchContainer .searchFieldContainer .searchFields .searchFieldText > span:first-child {
  font-size: 1rem;
  font-weight: 600;
}

.appContainer .header .searchContainer .searchFieldContainer .searchFields .searchFieldText > span:last-child {
  font-size: 1rem;
  font-weight: 300;
}

.appContainer .header .searchContainer .searchFields img {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  object-fit: cover;
}

.appContainer .header input {
  width: 18rem;
  padding: 1rem 3rem 1rem 4rem;
  border: none;
  border-radius: 20rem;
  outline: none;
  color: var(--dark-blue);
}

.appContainer .header input::placeholder {
  color: var(--grey);
}

.closeModal {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
}

.appContainer .moreBtn {
  background: var(--blue);
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 1rem 0;
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translate(-50%, -50%);
  z-index: 998;
  cursor: pointer;
}

.postForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2rem;
  width: 100%;
}

.inputGroup label {
  margin-bottom: 0.5rem;
}

.inputGroup input {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 2px solid var(--blue);
  border-radius: 20rem;
  outline: none;
  color: var(--dark-blue);
}

.imageInput {
  width: 100%;
  position: relative;
}

.imageInput .image {
  height: 13.75rem;
  width: 100%;
  background: #F5F8FA;
  border: 2px dashed var(--blue);
  border-radius: 20px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.imageInput .imageContainer {
  height: 13.75rem;
  width: 100%;
  margin-bottom: 1.5rem;
  position: relative;
}

.imageInput .imageContainer .imageCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 0 20px 0 20px;
  padding: 0.5rem;
  border: 2px solid var(--blue-secondary);
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
}

.imageInput .imageContainer img.image {
  border: 2px solid var(--blue-secondary);
  cursor: default;
  object-fit: cover;
}

.imageInput input {
  visibility: hidden;
  display: none;
}

.appContainer .feedContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
}

.appContainer .feedContainer .feedHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appContainer > .feedContainer .feedHeader h2 {
  font-size: 1.4rem
}

.appContainer > .feedContainer .feedHeader .feedOptions {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.appContainer > .feedContainer .feedHeader .feedOptions > * {
  margin: 0 0.5rem;
  font-size: 1.4rem;
  font-weight: 300;
  cursor: pointer;
}

.appContainer > .feedContainer .feedHeader .feedOptions > *:not(.selected):hover {
  text-decoration: underline;
}

.appContainer > .feedContainer .feedHeader .feedOptions > *.selected {
  /* text-decoration: underline; */
  font-weight: 600;
}

.appContainer > .feedContainer > .posts {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
}

.button {
  width: 90%;
  max-width: 420px;
  border: none;
  border-radius: 2rem;
  padding: 1rem 2rem;
  transition: 0.2s linear;
  background: var(--blue);
  cursor: pointer;
  color: #fff;
  font-weight: 300;
  outline: none;
}

.button:hover {
  background: var(--blue-secondary);
  color: #fff;
}

.button:disabled {
  background: var(--grey);
  cursor: default;
}

@media only screen and (max-width: 768px) {
  .app {
    display: block;
  }

  .appContainer {
    padding: 1rem;
    width: 100%;
    margin-left: 0;
    padding-bottom: 5rem;
    padding-top: 9rem;
  }

  .appContainer .header .searchContainer {
    display: none;
  }

  .moreBtn {
    display: none !important;
  }

  .appContainer > .feedContainer > .posts {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}