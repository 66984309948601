.headerMobileContainer {
  display: none;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  
  width: 100%;
  height: 7.5rem;
  padding: 2rem;

  background: #fff;

  z-index: 999;
}
.headerMobileContainer img {
  width: 3.14rem;
}

@media only screen and (max-width: 768px) {
  .headerMobileContainer {
    display: flex;
  }
}