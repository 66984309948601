.modalContainer {
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.7);

  position: fixed;
  top: 0;

  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 90%;
  max-width: 500px;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  position: relative;
}

.modalTitle {
  color: var(--dark-blue);
  font-weight: 600;
}