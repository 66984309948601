.menuContainer {
  width: 6rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-right: 2px solid var(--grey);
  padding: 0.5rem;
  position: fixed;
  z-index: 999;
}

.menuContainer .logo {
  width: 3.2rem;
  margin: 2rem 0;
}

.menuContainer ul {
  list-style: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menuContainer ul li {
  padding: 1.5rem;
}

.menuContainer ul li.toMobile {
  display: none;
}

.active svg {
  color: var(--blue) !important;
}

.moreBtn {
  background: var(--blue);
  width: 5.44rem;
  height: 5.44rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 1rem 0;
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0.8rem solid var(--light-grey);
}

.profile {
  position: relative;
  width: 3.1rem;
  height: 3.1rem;
  margin: 2rem 0;
}

li > .profile {
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  margin: 0;
}

.profile img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid var(--green);
}

.status {
  width: 1.19rem;
  height: 1.19rem;
  background: var(--green);
  border: 4px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: -5%;
  right: -5%;
}

@media only screen and (max-width: 768px) {
  .menuContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    flex-direction: row;
    height: initial;
    justify-content: center;
    border-right: none;
    padding-bottom: 1.5rem;
  }

  .menuContainer .logo {
    display: none;
  }

  .menuContainer ul {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .menuContainer ul > li {
    padding: 1rem;
  }

  .menuContainer ul > li:nth-child(2) {
    margin-right: 4rem;
  }

  .menuContainer ul > li:nth-child(3), .menuContainer ul > li:nth-child(6), .menuContainer ul > li:nth-child(7) {
    display: none;
  }

  .menuContainer ul li.toMobile {
    display: block;
  }

  .menuContainer ul li > .profile {
    display: block;
  }

  .profile {
    display: none;
  }

  .profile img {
    border: 2px solid var(--green);
  }

  .status {
    display: none;
  }
}