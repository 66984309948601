.footer {
  height: 7vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  color: #fff;
  font-weight: 300;
}

.footer p {
  font-size: 1.2rem;
}