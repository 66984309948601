.app {
  background: var(--light-grey);
  width: 100%;
  min-height: 100vh;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
}

.profileContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  color: var(--dark-blue);
  margin-left: 6rem;
}

.profileContainer .postsDetails {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 70rem;
}

.profileContainer .profileDetails  {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: #fff;
  margin-bottom: 1rem;
  border-radius: 0.2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-align: center;
  width: 100%;
  max-width: 70rem;
  flex-wrap: wrap;
}

.profileContainer .profileDetails > * {
  margin: 2rem;
}

.profileContainer .profileDetails .personalDetails {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profileContainer .profileDetails .personalDetails > :last-child {
  text-align: left;
}

.profileContainer .profileDetails .personalDetails img {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1rem;
}

.profileContainer .profileDetails .otherDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.profileContainer .profileDetails .otherDetails > .followButton {
  width: 90%;
  max-width: 420px;
  border: none;
  border-radius: 2rem;
  padding: 1rem 2rem;
  transition: 0.2s linear;
  background: var(--blue);
  cursor: pointer;
  color: #fff;
  font-weight: 300;
  outline: none;
  margin-top: 2rem;
}

.profileContainer .profileDetails .otherDetails > .followButton:hover {
  background: var(--blue-secondary);
  color: #fff;
}

.profileContainer .profileDetails .otherDetails > .unfollowButton {
  width: 90%;
  max-width: 420px;
  border: none;
  border-radius: 2rem;
  padding: 1rem 2rem;
  transition: 0.2s linear;
  background: #fff;
  border: 2px solid var(--blue);
  cursor: pointer;
  color: var(--blue);
  font-weight: 300;
  outline: none;
  margin-top: 2rem;
}

.profileContainer .profileDetails .otherDetails > .unfollowButton:hover {
  background: var(--blue-secondary);
  border: 2px solid var(--blue-secondary);
  color: #fff;
}

.profileContainer .profileDetails .otherDetails > .otherDetailsTexts {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.profileContainer .profileDetails .otherDetails > .otherDetailsTexts > .otherDetailsText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
}

.profileContainer .profileDetails .otherDetails > .otherDetailsTexts > .otherDetailsText > span:first-child {
  font-size: 1.2rem;
  font-weight: 600;
}

.profileContainer .postsDetails .postsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  background: #fff;
  width: 100%;

  margin: 0.5rem 0;

  border-radius: 0.2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.profileContainer > .postsDetails > .postsContainer > .posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
}

.closeModal {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .app {
    display: block;
  }

  .profileContainer {
    padding: 1rem;
    width: 100%;
    margin-left: 0;
    padding-bottom: 7rem;
    padding-top: 8.5rem;
  }

  .profileContainer .profileDetails {
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .profileContainer .profileDetails .personalDetails {
    flex-direction: column;
  }

  .profileContainer .profileDetails .personalDetails > :last-child {
    text-align: center;
  }

  .profileContainer .profileDetails .otherDetails > .otherDetailsTexts {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .profileContainer .profileDetails .otherDetails > .otherDetailsTexts > .otherDetailsText {
    margin: 1rem;
  }

  /* .profileContainer .profileDetails .personalDetails, .profileContainer .profileDetails .otherDetails  {
    flex-direction: column;
    height: initial;
    text-align: center;
  } */

  .profileContainer .profileDetails .personalDetails img {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .profileContainer > .postsDetails > .postsContainer > .posts {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}