.postContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  position: relative;
  margin: 1rem 0;
  white-space: pre-wrap;
}

.instagram-heart {   
  width: 184px;
  height: 162px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  margin: auto;
  background: url("https://imgur.com/rksyGE8.png") no-repeat center/contain;
  opacity: 0;
}

.postImage.active .instagram-heart {
  animation-duration: 1000ms;
  animation-name: like-heart-animation;
  animation-timing-function: ease-in-out;
}

@keyframes like-heart-animation{ 
  0% { opacity:0; transform:  translate(-50%, -50%) scale(0); }
  15% { opacity:.9; transform:  translate(-50%, -50%) scale(1.2); }
  30% { transform:  translate(-50%, -50%) scale(.95); }
  45%,
  80% { opacity:.9; transform:  translate(-50%, -50%) scale(1); }
}

.postContainer .postImage {
  width: 100%;
  height: 20rem;
  object-fit: cover;
  cursor: pointer;
  position: relative;
}

.postContainer .postImage img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}

.postContainer .postDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 0 1rem;
}

.postContainer .postDetails .author {
  display: flex;
  justify-content: center;
  align-items: center;
}

.postContainer .postDetails .author img {
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid var(--green);
  margin-right: 0.5rem;
}

.postContainer .postDetails .otherDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.postContainer .postDetails .otherDetails > :nth-child(1) {
  margin-right: 1.5rem;
}

.postContainer .postDetails .otherDetails div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.postContainer .postDetails .otherDetails div > span {
  margin-left: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .postContainer {
    width: 100%;
    margin: 1rem 0;
  }
}